import React from "react";

const Final = () =>{
    return(
        <div className="eliminatorias-container">
            <h4 className="titleOitavas">FINAL</h4>
            <div className="eliminatorias-match">
            <span className="jogador">Leleco</span><span className="placar">2</span>X<span className="placar">0</span><span className="jogador">-ф-РЭXЭBЯ-ф-</span>
            </div>
            
        </div>
    )
}

export default Final;